import { SyntheticEvent, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { CheckoutInterface, CheckoutSteps } from "./CheckoutTypes";
import {
  ArrowBackRounded,
  CancelRounded,
  CheckOutlined,
  CloseRounded,
  EastOutlined,
  EventAvailableRounded,
  HistoryRounded,
  QueryBuilderRounded,
  RestaurantRounded,
  StoreRounded,
  TwoWheelerOutlined,
} from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { numberToBRL } from "../../utils/formatters";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  InputAdornment,
  LinearProgress,
  ListItemAvatar,
  MenuItem,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { brazilianStates, MONEY_METHOD_CODE } from "../../utils/static";
import { api } from "../../services/api";
import CheckoutSection from "./CheckoutSection";
import { theme } from "../../styles/theme";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deliveryWayDeliveryId,
  deliveryWayPickupId,
} from "../../services/static";
import useDialog from "../../hooks/useDialog";
import useDevice from "../../hooks/useDevice";
import { homeDataState } from "../../state/atoms/homeData";
import useCart from "../../hooks/useCart";
import { FilterPaymentMethodAPI } from "../../interfaces/filter";
import { NumericFormat, PatternFormat } from "react-number-format";
import useAccount from "../../hooks/useAccount";
import { cartDefaultState } from "../../state/atoms/cart";
import AutocompleteAddress from "../AutocompleteAddress/AutocompleteAddress";
import { PlaceType } from "../AutocompleteAddress/AutocompleteAddressTypes";
import { TransitionGroup } from "react-transition-group";
import { TransitionUp } from "../Transition/Transition";
import dayjs from "dayjs";
import { getWeekdayName } from "../../utils/dates";
import { HomeDataInterface } from "../../interfaces/home";
import { homeDataIsOpenNow } from "../../state/selectors/homeData";
import useCompanyFeatures from "../../hooks/companyPlan";

export default function Checkout({}: CheckoutInterface) {
  const location = useLocation();
  const navigate = useNavigate();
  const cart = useCart();
  const account = useAccount();
  const dialog = useDialog();
  const homeData = useRecoilValue(homeDataState);
  const isOpenNow = useRecoilValue(homeDataIsOpenNow);
  const isOpen = location.pathname.includes("/carrinho");
  const [paymentMethods, setPaymentMethods] = useState<
    FilterPaymentMethodAPI[]
  >([]);
  const [currTab, setCurrTab] = useState<CheckoutSteps>("products");
  const [isScheduledOrder, setIsScheduledOrder] = useState<null | boolean>(
    null
  );
  const { isMobile } = useDevice();
  const { hasFeature } = useCompanyFeatures();

  const [cartForm, setCartForm] = useState(cartDefaultState);

  const scheduleDate = dayjs(cartForm.scheduled_at).format("YYYY-MM-DD");
  const scheduleWeekday = getWeekdayName(dayjs(cartForm.scheduled_at).day());
  const dayOpeningHours = homeData.company.opening_hours[scheduleWeekday];
  const selected = dayjs(cartForm.scheduled_at);
  const initial = dayjs(`${scheduleDate} ${dayOpeningHours?.initial}`);
  const final = dayjs(`${scheduleDate} ${dayOpeningHours?.final}`);

  const isValidSchedule =
    (selected.isAfter(initial) || selected.isSame(initial)) &&
    (selected.isBefore(final) || selected.isSame(final));

  const handleClose = () => {
    navigate(`../`);
  };

  const handleChangeTab = (e: SyntheticEvent, newTab: CheckoutSteps) => {
    cart.changePaymentMethod(null);
    setCurrTab(newTab);
  };

  const handleSubmitOrder = () => {
    cart.setLoading(true);
    cart
      .finishCart()
      .then((resp) => {
        account.setToken(resp.data.token);
        account.setPreviousOrder(cartForm);

        cart.deleteCart();
        cart.setLoading(false);

        handleClose();
        navigate(`./sucesso/${resp.data.id}`);
      })
      .catch((err) => {
        cart.setLoading(false);
        dialog("Houve um problema ⚠️", err.response?.data?.message);
      });
  };

  const handleSaveDeliveryStepData = () => {
    cart.changeScheduleAt(
      cartForm.scheduled_at
        ? dayjs(cartForm.scheduled_at).format("YYYY-MM-DD HH:mm")
        : null,
      () => {
        cart.changeDeliveryWay(cartForm.delivery_way, () => {
          if (cartForm.delivery_way === deliveryWayPickupId) {
            setCurrTab("payment");
          } else {
            if (
              cartForm.address.street &&
              cartForm.address.number &&
              cartForm.address.neighborhood &&
              cartForm.address.city &&
              cartForm.address.state
            ) {
              cart.changeDeliveryAddress(cartForm.address, () =>
                setCurrTab("payment")
              );
            } else {
              dialog(
                "Endereço incompleto ⚠️",
                "Preencha todos os detalhes do endereço de entrega antes de prosseguir."
              );
            }
          }
        });
      }
    );
  };

  const handleFillWithPreviousOrder = () => {
    cart.fillWithPreviousOrder(() => setIsScheduledOrder(!isOpenNow));
  };

  const handleSaveProductsStepData = () => {
    cart.changeObservation(cartForm.observation, () => setCurrTab("delivery"));
  };

  const handleSavePaymentStepData = () => {
    cart.changePaymentChange(Number(cartForm.change), () => {
      if (cartForm.coupon) {
        cart.addDiscountCoupon(cartForm.coupon, () => setCurrTab("customer"));
      } else {
        setCurrTab("customer");
      }
    });
  };

  const handleSaveCustomerStepData = () => {
    cart.changeCustomer(cartForm.customer, handleSubmitOrder);
  };

  const handleChangeAddressAutoComplete = (address: PlaceType | null) => {
    if (address) {
      setCartForm((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          state: address.terms[address.terms.length - 2].value ?? "",
          city: address.terms[address.terms.length - 3].value ?? "",
          neighborhood: address.terms[address.terms.length - 4].value ?? "",
          street: address.terms[0].value.split(",")[0] ?? "",
          number: "",
          complement: "",
          zipcode: "",
        },
      }));
    }
  };

  useEffect(() => {
    api
      .get<FilterPaymentMethodAPI[]>(
        `/filter/payment-methods/${homeData.company.id}`,
        {
          params: {
            restrict_to_delivery_way_id: cartForm.delivery_way,
          },
        }
      )
      .then((resp) => {
        setPaymentMethods(resp.data);
      });
  }, [cartForm.delivery_way]);

  useEffect(() => {
    if (cart.data) {
      setCartForm(cart.data);
    }
  }, [cart.data]);

  const renderOpeningHour = (
    dayName: string,
    dayKey: keyof HomeDataInterface["company"]["opening_hours"]
  ) => {
    const { initial, final } = homeData.company.opening_hours[dayKey];

    return (
      <p>
        {dayName} —{" "}
        {initial === null && final === null
          ? "fechado"
          : `${initial} às ${final}`}
      </p>
    );
  };

  const CheckoutResume = () => {
    const stepActions = {
      products: handleSaveProductsStepData,
      delivery: handleSaveDeliveryStepData,
      payment: handleSavePaymentStepData,
      customer: handleSaveCustomerStepData,
    };

    const onClickAction = stepActions[currTab];
    const isLastStep = currTab === "customer";
    let validationError = false;

    if (currTab === "products") {
      if (cartForm.products.length === 0) {
        validationError = true;
      }
    }

    if (currTab === "delivery") {
      if (cartForm.delivery_way === null) {
        validationError = true;
      }

      if (
        cartForm.delivery_way === deliveryWayDeliveryId &&
        (!cartForm.address.number ||
          !cartForm.address.street ||
          !cartForm.address.neighborhood ||
          !cartForm.address.city ||
          !cartForm.address.state)
      ) {
        validationError = true;
      }

      if (isScheduledOrder && !isValidSchedule) {
        validationError = true;
      }

      if (!isOpenNow && hasFeature("scheduled-orders") && !isValidSchedule) {
        validationError = true;
      }
    }

    if (currTab === "payment") {
      if (cartForm.payment_method_id === null) {
        validationError = true;
      }
    }

    if (currTab === "customer") {
      if (!cartForm.customer.name || !cartForm.customer.phone) {
        validationError = true;
      }
    }

    return (
      <div
        style={{
          background: theme.background,
          position: "fixed",
          zIndex: 10,
          left: 0,
          bottom: 0,
          width: "100%",
          boxShadow: "0px 0px 25px rgba(0,0,0,0.1)",
        }}
      >
        <Container maxWidth={isMobile ? false : "lg"} disableGutters>
          <List>
            <ListItem style={{ justifyContent: "space-between" }}>
              <div>
                <Typography color={theme.tertiary}>
                  Produtos: {numberToBRL(cartForm.subtotal)}
                </Typography>

                {cartForm.delivery_tax > 0 && (
                  <Typography color={theme.tertiary}>
                    Entrega: {numberToBRL(cartForm.delivery_tax)}
                  </Typography>
                )}
                {(cartForm.increase > 0 ||
                  cartForm.increase_tax_method > 0) && (
                  <Typography color={theme.tertiary}>
                    Acréscimo:{" "}
                    {numberToBRL(
                      cartForm.increase + cartForm.increase_tax_method
                    )}
                  </Typography>
                )}

                {cartForm.discount > 0 && (
                  <Typography color={theme.tertiary}>
                    Desconto: {numberToBRL(cartForm.discount)}
                  </Typography>
                )}

                <Typography>
                  <b>Total: {numberToBRL(cartForm.total)}</b>
                </Typography>
              </div>
              <Button
                variant="contained"
                size="large"
                disableElevation
                endIcon={isLastStep ? <CheckOutlined /> : <EastOutlined />}
                onClick={onClickAction}
                disabled={validationError || cart.loading}
              >
                {isLastStep ? "Enviar" : "Próximo"}
              </Button>
            </ListItem>
          </List>
        </Container>
        <LinearProgress style={{ opacity: cart.loading ? 1 : 0 }} />
      </div>
    );
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
    >
      <AppBar sx={{ position: "sticky" }}>
        <Container maxWidth={isMobile ? false : "lg"} disableGutters>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              size="large"
              aria-label="close"
            >
              <ArrowBackRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Meu pedido
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth={isMobile ? false : "lg"} disableGutters>
        <Tabs
          value={currTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value="products" label="Produtos" />
          <Tab
            value="delivery"
            label="Entrega"
            disabled={currTab === "products"}
          />
          <Tab
            value="payment"
            label="Pagamento"
            disabled={currTab === "products" || currTab === "delivery"}
          />
          <Tab
            value="customer"
            label="Cliente"
            disabled={
              currTab === "products" ||
              currTab === "delivery" ||
              currTab === "payment"
            }
          />
        </Tabs>
        <CheckoutSection show={currTab === "products"}>
          <List>
            <TransitionGroup>
              {cartForm.products.map((product) => {
                return (
                  <Collapse key={product.id}>
                    <ListItem
                      key={product.id}
                      alignItems="flex-start"
                      divider
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="inherit"
                          style={{ color: "var(--tertiary)" }}
                          onClick={() => cart.removeProduct(product.id)}
                        >
                          <CloseRounded />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={product.name}
                          src={product.photo_path ?? ""}
                          variant="rounded"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={product.name}
                        secondary={
                          <>
                            <Typography color="text.secondary" variant="body2">
                              {product.kit &&
                                product.kit_products.map((item) => (
                                  <span style={{ display: "block" }}>
                                    • {item.name}
                                  </span>
                                ))}

                              {product.product_complements_id.map(
                                (complement) => {
                                  return (
                                    <div>
                                      {complement.title} —{" "}
                                      {numberToBRL(complement.price)}
                                    </div>
                                  );
                                }
                              )}
                            </Typography>

                            <Typography color="primary">
                              {numberToBRL(product.total)}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  </Collapse>
                );
              })}
            </TransitionGroup>
            <Collapse in={cartForm.products.length === 0}>
              <Box paddingY={5}>
                <Typography color={theme.tertiary} textAlign="center">
                  Nenhum produto no carrinho... 😔
                </Typography>
              </Box>
            </Collapse>
            <ListItem>
              <TextField
                label="Observações"
                InputLabelProps={{ shrink: true }}
                multiline
                minRows={3}
                variant="outlined"
                fullWidth
                style={{ marginTop: 15 }}
                value={cartForm.observation}
                onChange={(e) =>
                  setCartForm((prev) => ({
                    ...prev,
                    observation: e.target.value,
                  }))
                }
              />
            </ListItem>
          </List>
        </CheckoutSection>
        <CheckoutSection show={currTab === "delivery"}>
          <List>
            <Collapse in={!!account.previousOrder && isScheduledOrder === null}>
              {!isOpenNow && hasFeature("scheduled-orders") && (
                <ListItem>
                  <Alert
                    variant="standard"
                    severity="warning"
                    icon={<EventAvailableRounded />}
                    style={{ alignItems: "center" }}
                  >
                    <Typography>
                      A loja está fechada no momento, mas você ainda pode{" "}
                      <b>agendar um pedido</b>.
                    </Typography>
                  </Alert>
                </ListItem>
              )}

              <ListItem>
                <Alert
                  color="info"
                  variant="outlined"
                  icon={<HistoryRounded fontSize="large" />}
                  sx={{ alignItems: "center" }}
                  action={
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={handleFillWithPreviousOrder}
                    >
                      confirmar
                    </Button>
                  }
                >
                  Confirme para preencher com os mesmos detalhes do seu{" "}
                  <b>último pedido</b>.
                </Alert>
              </ListItem>
            </Collapse>
            <ListItem sx={{ justifyContent: "space-between" }}>
              <Typography>
                <b>Quando</b> deseja receber o pedido?
              </Typography>
              <Collapse in={isScheduledOrder !== null}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setIsScheduledOrder(null)}
                >
                  {isScheduledOrder ? "Agendar" : "Agora"}
                </Button>
              </Collapse>
            </ListItem>
            <Collapse in={isScheduledOrder === null}>
              <ListItem>
                <Grid container spacing={2} marginBottom="15px">
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      size="large"
                      startIcon={<RestaurantRounded />}
                      fullWidth
                      onClick={() => setIsScheduledOrder(false)}
                      disabled={!isOpenNow}
                    >
                      Agora
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      size="large"
                      startIcon={<EventAvailableRounded />}
                      fullWidth
                      onClick={() => setIsScheduledOrder(true)}
                      disabled={!hasFeature("scheduled-orders")}
                    >
                      Agendar
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </Collapse>
            <Collapse in={!!isScheduledOrder}>
              <ListItem sx={{ columnGap: 1.5 }}>
                <TextField
                  type="datetime-local"
                  fullWidth
                  value={
                    cartForm.scheduled_at
                      ? dayjs(cartForm.scheduled_at).format("YYYY-MM-DD HH:mm")
                      : null
                  }
                  onChange={(e) => {
                    setCartForm((prev) => ({
                      ...prev,
                      scheduled_at: dayjs(e.target.value).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                    }));
                  }}
                />
              </ListItem>
              <Collapse in={!!isScheduledOrder && !isValidSchedule}>
                <ListItem>
                  <Alert
                    color="error"
                    icon={<QueryBuilderRounded />}
                    sx={{ width: "100%" }}
                  >
                    <Typography fontWeight="bold">
                      Horário de funcionamento
                    </Typography>
                    <Typography variant="body2">
                      {renderOpeningHour("Segunda-feira", "monday")}
                      {renderOpeningHour("Terça-feira", "tuesday")}
                      {renderOpeningHour("Quarta-feira", "wednesday")}
                      {renderOpeningHour("Quinta-feira", "thursday")}
                      {renderOpeningHour("Sexta-feira", "friday")}
                      {renderOpeningHour("Sábado", "saturday")}
                      {renderOpeningHour("Domingo", "sunday")}
                    </Typography>
                  </Alert>
                </ListItem>
              </Collapse>
            </Collapse>
            <Collapse in={isScheduledOrder !== null}>
              <ListItem sx={{ justifyContent: "space-between" }}>
                <Typography>
                  Qual será a <b>forma de entrega</b>?
                </Typography>
                <Collapse in={cartForm.delivery_way !== null}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      setCartForm((prev) => ({
                        ...prev,
                        delivery_way: null,
                      }))
                    }
                  >
                    {cartForm.delivery_way === deliveryWayPickupId
                      ? "Retirada"
                      : "Entrega"}
                  </Button>
                </Collapse>
              </ListItem>
              <Collapse in={cartForm.delivery_way === null}>
                <ListItem>
                  <Grid container spacing={2} marginBottom="15px">
                    <Grid item xs={6}>
                      <Button
                        variant={
                          cartForm.delivery_way === deliveryWayDeliveryId
                            ? "contained"
                            : "outlined"
                        }
                        size="large"
                        startIcon={<TwoWheelerOutlined />}
                        fullWidth
                        onClick={() =>
                          setCartForm((prev) => ({
                            ...prev,
                            delivery_way: deliveryWayDeliveryId,
                          }))
                        }
                      >
                        Entrega
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant={
                          cartForm.delivery_way === deliveryWayPickupId
                            ? "contained"
                            : "outlined"
                        }
                        size="large"
                        startIcon={<StoreRounded />}
                        fullWidth
                        onClick={() =>
                          setCartForm((prev) => ({
                            ...prev,
                            delivery_way: deliveryWayPickupId,
                          }))
                        }
                      >
                        Retirada
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </Collapse>
            </Collapse>
            <Collapse
              in={
                cartForm.delivery_way === deliveryWayDeliveryId &&
                isScheduledOrder !== null
              }
            >
              <ListItem>
                <Typography>
                  Onde é o <b>endereço de entrega</b>?
                </Typography>
              </ListItem>

              <Collapse in={!cartForm.address.state}>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <AutocompleteAddress
                        onChange={handleChangeAddressAutoComplete}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Collapse>

              <Collapse in={!!cartForm.address.state}>
                <ListItem>
                  <Grid container spacing={2} paddingTop={0.5}>
                    <Grid item xs={12}>
                      <TextField
                        label="Rua"
                        variant="outlined"
                        fullWidth
                        value={cartForm.address.street}
                        onChange={(e) =>
                          setCartForm((prev) => ({
                            ...prev,
                            address: {
                              ...prev.address,
                              street: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        label="Número"
                        variant="outlined"
                        fullWidth
                        error={
                          !!cartForm.address.state && !cartForm.address.number
                        }
                        value={cartForm.address.number}
                        onChange={(e) =>
                          setCartForm((prev) => ({
                            ...prev,
                            address: {
                              ...prev.address,
                              number: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="Complemento"
                        variant="outlined"
                        fullWidth
                        value={cartForm.address.complement}
                        onChange={(e) =>
                          setCartForm((prev) => ({
                            ...prev,
                            address: {
                              ...prev.address,
                              complement: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <TextField
                    label="Bairro"
                    variant="outlined"
                    fullWidth
                    value={cartForm.address.neighborhood}
                    onChange={(e) =>
                      setCartForm((prev) => ({
                        ...prev,
                        address: {
                          ...prev.address,
                          neighborhood: e.target.value,
                        },
                      }))
                    }
                  />
                </ListItem>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        label="Cidade"
                        variant="outlined"
                        fullWidth
                        value={cartForm.address.city}
                        onChange={(e) =>
                          setCartForm((prev) => ({
                            ...prev,
                            address: {
                              ...prev.address,
                              city: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        select
                        label="Estado"
                        fullWidth
                        value={cartForm.address.state ?? ""}
                        onChange={(e) =>
                          setCartForm((prev) => ({
                            ...prev,
                            address: {
                              ...prev.address,
                              state: e.target.value,
                            },
                          }))
                        }
                      >
                        {brazilianStates.map((state) => (
                          <MenuItem key={state.code} value={state.code}>
                            {state.code}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </ListItem>
              </Collapse>
            </Collapse>
            <Collapse in={cartForm.delivery_way === deliveryWayPickupId}>
              <ListItem>
                <Typography>
                  Onde é o <b>endereço de retirada</b>:
                </Typography>
              </ListItem>
              <ListItem>
                <Box
                  padding={2}
                  sx={{ border: `1px solid #dbdbdb` }}
                  borderRadius={1.5}
                >
                  <Typography
                    fontWeight="bold"
                    color={theme.primary}
                    textTransform="uppercase"
                  >
                    {homeData.company.name}
                  </Typography>
                  <Typography>{homeData.company.address}</Typography>
                </Box>
              </ListItem>
            </Collapse>
          </List>
        </CheckoutSection>
        <CheckoutSection show={currTab === "payment"}>
          <List>
            <ListItem sx={{ paddingTop: "20px" }}>
              <TextField
                select
                label="Forma de pagamento"
                fullWidth
                value={cartForm.payment_method_id}
                onChange={(e) => {
                  cart.changePaymentMethod(e.target.value);
                }}
              >
                {paymentMethods.map((method) => (
                  <MenuItem key={method.value} value={method.value}>
                    {method.label}{" "}
                    {method.taxes > 0 ? ` (acréscimo de ${method.taxes}%)` : ""}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
            <Collapse in={cartForm.payment_method_id === MONEY_METHOD_CODE}>
              <ListItem>
                <NumericFormat
                  value={cartForm.change}
                  onValueChange={(values) => {
                    setCartForm((prev) => ({
                      ...prev,
                      change: values.floatValue || 0,
                    }));
                  }}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                  fullWidth
                  customInput={TextField}
                  label="Troco para quanto?"
                  helperText="Deixe vazio caso não precise"
                  variant="outlined"
                />
              </ListItem>
            </Collapse>
            <ListItem>
              <TextField
                label="Cupom de desconto"
                placeholder="ex: OFF10"
                inputProps={{ style: { textTransform: "uppercase" } }}
                InputProps={{
                  endAdornment: !!cart.data.coupon ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => cart.removeDiscountCoupon()}
                        edge="end"
                      >
                        <CancelRounded />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
                variant="outlined"
                fullWidth
                disabled={!!cart.data.coupon}
                value={cartForm.coupon}
                onChange={(e) =>
                  setCartForm((prev) => ({
                    ...prev,
                    coupon: e.target.value,
                  }))
                }
              />
            </ListItem>
          </List>
        </CheckoutSection>
        <CheckoutSection show={currTab === "customer"}>
          <List>
            <ListItem>
              <TextField
                label="Nome completo"
                variant="outlined"
                fullWidth
                value={cartForm.customer.name}
                onChange={(e) =>
                  setCartForm((prev) => ({
                    ...prev,
                    customer: {
                      ...prev.customer,
                      name: e.target.value,
                    },
                  }))
                }
              />
            </ListItem>
            <ListItem>
              <PatternFormat
                value={cartForm.customer.phone}
                onChange={(e) =>
                  setCartForm((prev) => ({
                    ...prev,
                    customer: {
                      ...prev.customer,
                      phone: e.target.value,
                    },
                  }))
                }
                format={"(##) #####-####"}
                fullWidth
                mask="_"
                customInput={TextField}
                label="Telefone (WhatsApp)"
                variant="outlined"
                type="tel"
              />
            </ListItem>
            <ListItem>
              <PatternFormat
                value={cartForm.customer.document}
                onChange={(e) =>
                  setCartForm((prev) => ({
                    ...prev,
                    customer: {
                      ...prev.customer,
                      document: e.target.value,
                    },
                  }))
                }
                format={"###.###.###-##"}
                fullWidth
                mask="_"
                customInput={TextField}
                label="CPF (opcional)"
                helperText="Informe seu CPF caso precise do cupom fiscal do pedido."
                variant="outlined"
                type="tel"
              />
            </ListItem>
          </List>
        </CheckoutSection>
      </Container>
      <CheckoutResume />
    </Dialog>
  );
}
